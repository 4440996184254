<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Purchased Value Pass</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="DateCreated"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ValuePassTrxRef}}</td>
                            <td>{{props.item.PaymentProvider3rdPartyRefNo}}</td>
                            <td>{{props.item.PaymentMethod}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre;">{{props.item.CarParkName}}</td>
                            <td>{{props.item.loginMobileID}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.mobile}}</td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.VPPurchaseTransactionDate}}</td>
                            <td>{{props.item.ValuePassProductID}}</td>
                            <td>{{props.item.ValuePassName}}</td>
                            <td>{{props.item.EndUseDate}}</td>
                            <td>{{props.item.VPPurchaseQuantity}}</td>
                            <td>{{props.item.AmountGrandTotal}}</td>
                            <td>
                                <v-tooltip top v-if="props.item.VPInvoiceReceiptURL !== null">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_pdf')">{{props.item.VPInvoiceReceiptURLText}}</a>
                                </template>
                                <span>View Bill</span>
                                </v-tooltip>
                                <v-tooltip v-else-if="props.item.VPInvoiceReceiptURL === null" >
                                    <template v-slot:activator="{ on }">
                                    <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_pdf')">N/A</a>
                                    </template>
                                    <span>View Bill</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSystem';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Purchased Value Pass',
    title: 'Purchased Value Pass',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Transaction',
                disabled: true,
                href: '#',
                },

                {
                text: 'Mobile Subscriber',
                disabled: true,
                href: '#',
                },

                {
                text: 'Purchased Value Pass',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Ref No', value: 'ValuePassTrxRef', width: '3%' },
                { text: 'Transaction ID', value: 'PaymentProvider3rdPartyRefNo', width: '3%' },
                { text: 'Payment Method', value: 'PaymentMethod', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '35%' },
                { text: 'Mobile ID', value: 'loginMobileID', width: '5%' },
                { text: 'Name', value: 'name', width: '5%' },
                { text: 'Mobile No.', value: 'mobile', width: '5%' },
                { text: 'Email', value: 'email', width: '10%' },
                { text: 'Transaction Date', value: 'VPPurchaseTransactionDate', width: '6%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'VP ID', value: 'ValuePassProductID', width: '3%' },
                { text: 'VP Name', value: 'ValuePassName', width: '5%' },
                { text: 'Expiry Date', value: 'EndUseDate', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Quantity', value: 'VPPurchaseQuantity', width: '3%' },
                { text: 'Amount (RM)', value: 'AmountGrandTotal', width: '3%' },
                { text: 'Bill No.', value: 'VPInvoiceReceiptURLText', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_pdf: '',
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
        }
    },

    components: {create, alert, alertbox2, viewpdf},

    async created(){
        await this.getCarParkFull();
        window.addEventListener("unload", (evt) => this.onUnload({operator: this.operator.selected, carpark: this.carpark.selected}, evt));
        await this.getOperatorFull();
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator || this.operator.selected;
        await this.getCarParkFull();
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        this.getValuePass(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getValuePass();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async getValuePass(pg){
            /*
            let chosen = '';
            if (this.toggle_exclusive_search == 0){ chosen = {mobileno: this.search}};
            if (this.toggle_exclusive_search == 1){ chosen = {valuepassname: this.search}};
            if (this.toggle_exclusive_search == 2){ chosen = {valuepassproductid: this.search}};
            */
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getValuePassPurchaseTransaction", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, search: this.search });

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].displaystatus = dt[i].MobileDisplayStatus == 1 ? 'Yes' : 'No';
                    dt[i].startendusedate = `${moment(dt[i].StartUseDate).format('DD-MM-YYYY')} - ${moment(dt[i].EndUseDate).format('DD-MM-YYYY hh:mm:ss A')}`;
                    dt[i].EndUseDate = moment(dt[i].EndUseDate).format('DD-MM-YYYY');
                    dt[i].VPPurchaseTransactionDate = moment(dt[i].VPPurchaseTransactionDate).format('DD-MM-YYYY hh:mm:ss A');
                    let url = String(dt[i].VPInvoiceReceiptURL);
                    url = url.substring(url.lastIndexOf('/') + 1);
                    dt[i].VPInvoiceReceiptURLText = url.split('.')[0];
                    dt[i].urltext = dt[i].VPInvoiceReceiptURLText; //for modal pdf saving file
                    dt[i].VPAmount = Number(dt[i].VPAmount).toFixed(2);
                    //replace(/\s/g,'') remove any literal whitespace

                    /*
                    let arrfilteritems = [
                            {name: 'operator', truth: this.operator.selected == '' ? false : true, selected: dt[i].OperatorID},
                            {name: 'carpark', truth: this.carpark.selected == '' ? false : true, selected: dt[i].CarParkID},
                        ];

                        let arrfilteritemsselected = arrfilteritems.filter(i => i.truth == true);

                        let listcode = arrfilteritemsselected;
                        dt[i].opcp = listcode;
                    */
                }

                /*
                if (this.operator.selected || this.carpark.selected){
                let arrfilter = [
                    {name: 'operator', truth: this.operator.selected == '' ? false : true, selected: this.operator.selected},
                    {name: 'carpark', truth: this.carpark.selected == '' ? false : true, selected: this.carpark.selected},
                ];

                let arrfilterselected = arrfilter.filter(i => i.truth == true);

                const objectsEqual = (o1, o2) => 
                typeof o1 === 'object' && Object.keys(o1).length > 0 
                    ? Object.keys(o1).length === Object.keys(o2).length 
                        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
                    : o1 === o2;

                let items = dt.filter(item => {
                    return objectsEqual(item.opcp, arrfilterselected);
                });

                    dt = items;
                }
                */
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                
                this.operator.items.push({name: "All Operators", OperatorID: ""});
                
                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    if (carpark.length > 0){

                        for(var i=0; i<carpark.length; i++){
                            carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                            this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }

                    else{
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = typeof this.carpark.items[0].CarParkID === undefined ? "" : this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                console.log(row);
                if(row.VPInvoiceReceiptURL == null){
                    const result = await this.getPDF(row);
                    if(result != ''){
                        this.pdfurl = result;
                        row.VPInvoiceReceiptURL = result;
                        this.ma.modal_pdf = true;
                    }
                }
                this.pdfurl = row.VPInvoiceReceiptURL;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        async getPDF(row){
            let paymentGateWay = row.PaymentMethod;
            if(paymentGateWay == 'Boost eWallet'){
                paymentGateWay = 'BOOSTEWALLET';
            } else if(paymentGateWay == "Touch N Go eWallet"){
                paymentGateWay = 'TOUCHNGOEWALLET'
            } else if(paymentGateWay == 'iPay88 FPX'){
                paymentGateWay = 'FPXDIRECT';
            }
            const response = await this.$store.dispatch('getVPPDF', {passRef: row.ValuePassTrxRef, paymentGateWay, carparkID: row.CarParkID});
            console.log(response.data)
            if(response.data.code == 'AP000'){
                return response.data.pdf;
            } else {
                return "";
            }
        },
        
        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
            }
        },

        /*
        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },
        */

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getValuePass(1);
          }, 800);
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }
    }
}
</script>